import React from 'react';
import { notification } from 'antd';

import VerifyTask from './VerifyTask';
import {
  denyWorkflow,
  getWorkflow,
  updateWorkflow,
  verifyWorkflow,
  cancelWorkflow,
} from '../../../../api/partnerRegistration';

type VerifyTaskContainerProps = {
  workflowId: string
}

const VerifyTaskContainer = ({ workflowId }: VerifyTaskContainerProps) => (
  <VerifyTask
    workflowId={workflowId}
    onLoad={async () => (
      getWorkflow(workflowId)
    )}
    onUpdate={async (data: any) => {
      try {
        await updateWorkflow(workflowId, data);
        return true;
      } catch (error: any) {
        notification.error({ message: error.message });
        return false;
      }
    }}
    onVerify={async () => {
      try {
        await verifyWorkflow(workflowId);
        return true;
      } catch (error: any) {
        notification.error({ message: error.message });
        return false;
      }
    }}
    onDeny={async () => {
      try {
        await denyWorkflow(workflowId);
        return true;
      } catch (error: any) {
        notification.error({ message: error.message });
        return false;
      }
    }}
    onCancel={async () => {
      try {
        await cancelWorkflow(workflowId);
        return true;
      } catch (error: any) {
        notification.error({ message: error.message });
        return false;
      }
    }}
  />
);

export {
  VerifyTaskContainer,
};
