import React from 'react';
import * as Yup from 'yup';
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import { Modal, InputNumber, Checkbox, Select, Button, Divider } from 'antd';
import { Formik, Form, ErrorMessage } from 'formik';

import './MultiTicketComponentProductFormModal.scss';
import { SchemaType } from '../../../../constants/schema';
import { InputField } from '../../../../utils/form-helpers/antd-formik';

const UNLIMITED_QUANTITY = -1;

const categoriesList = [{
  id: '4997e56d-3f99-4c8d-b27c-50c4c8a88ff8',
  name: 'Стандартный',
}, {
  id: '7064e974-8afc-4800-a8ff-8a7d0e7046b7',
  name: 'Детский',
}, {
  id: 'd06952d7-7238-4ba9-a6cb-af4c94dad715',
  name: 'Пенсионный',
}, {
  id: '3b4459bb-45a4-41e6-85a1-3a7c5b7730b4',
  name: 'Взрослый',
}, {
  id: '146ee6bb-a572-41ef-83f6-f652ef469dcf',
  name: '2 Взрослых',
}, {
  id: '7921e997-2229-4885-881c-b334fe515a67',
  name: '1 Взрослый',
}, {
  id: '5c1da15e-92e9-44c0-8edf-8d4f0538de42',
  name: 'Ребенок до 5 лет вкл.',
}, {
  id: '482b0867-6549-4ba2-8f43-9c17c9e490e8',
  name: 'Ребенок от 6 до 13 лет вкл.',
}, {
  id: 'f7fc0d89-36ec-4eef-b716-a5c8984e83ba',
  name: 'Ребенок от 14 лет вкл.',
}];

export class MultiTicketComponentProductFormModal extends React.Component {
  constructor() {
    super();
    this.onFinish = this.onFinish.bind(this);
  }

  async onFinish(values, { setSubmitting, setErrors }) {
    const { product, onCreateProduct, onUpdateProduct, onComplete } = this.props;
    try {
      if (product) {
        await onUpdateProduct(product.id, values);
      } else {
        await onCreateProduct(values);
      }
      onComplete();
    } catch (error) {
      setErrors(error);
      setSubmitting(false);
    }
  }

  validationSchema = Yup.object().shape({
    productGroupId: Yup.string().nullable().default(null),
    specification: Yup.object({
      type: Yup.string().default(SchemaType.MultiTicketComponent),
      version: Yup.number().default(1),
      title: Yup.string().required('Введите название продукта.'),
      description: Yup.string().required('Введите описание продукта.'),
      categories: Yup.array().min(1, 'Добавьте хотя бы одну категорию').of(
        Yup.object().shape({
          id: Yup.string().required(),
          name: Yup.string().required('Введите название категории.'),
          price: Yup.number().required('Введите цену продукта.').min(100, 'Мин. 100'),
        }),
      ),
      quantity: Yup.number().required('Введите количество билетов.'),
    }).required(),
  });

  render() {
    const { open, onCancel, productGroups } = this.props;
    const { product } = this.props;
    const title = product ? 'Обновить товар' : 'Добавить товар';
    const okText = product ? 'Обновить' : 'Добавить';
    const defaultProduct = {
      productGroupId: null,
      specification: {
        type: SchemaType.MultiTicketComponent,
        version: 1,
        title: 'Новый товар 1',
        description: 'Описание нового товара',
        categories: [{
          id: categoriesList[0].id,
          name: categoriesList[0].name,
          price: 100.0,
        }],
        quantity: 100,
      },
    }
    return (
      <Formik
        enableReinitialize={true}
        initialValues={product || defaultProduct}
        validationSchema={this.validationSchema}
        onSubmit={this.onFinish}
      >
        {({
            values,
            errors,
            handleSubmit,
            setFieldValue,
            setFieldTouched,
            initialValues,
          }) => {
          return (
            <Modal
              open={open}
              title={title}
              onCancel={onCancel}
              okText={okText}
              onOk={handleSubmit}
              destroyOnClose={true}
            >
              <div className="MultiTicketComponentProductFormModal">
                <Form>
                  <table width="100%">
                    <tbody>
                      <tr>
                        <td>
                          Название:<br />
                          <InputField name="specification.title" />
                          <ErrorMessage name={`specification.title`}>
                            {(msg) => <div className="MultiTicketComponentProductFormModal-error">{msg}</div>}
                          </ErrorMessage>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Описание:<br />
                          <InputField.TextArea name="specification.description" />
                          <ErrorMessage name={`specification.description`}>
                            {(msg) => <div className="MultiTicketComponentProductFormModal-error">{msg}</div>}
                          </ErrorMessage>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <Divider />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Категории:
                          <Button
                            type="link"
                            icon={<PlusOutlined />}
                            onClick={() => {
                              const freeCategory = categoriesList.find((category) => (
                                !values.specification.categories
                                  .map((selectedCategory) => selectedCategory.id)
                                  .includes(category.id)
                              ))
                              setFieldValue(`specification.categories[${values.specification.categories.length}]`, {
                                id: freeCategory.id,
                                name: freeCategory.name,
                                price: 100.0,
                              })
                            }}
                            disabled={values.specification.categories.length === categoriesList.length}
                          />
                          <br />
                          <table className="MultiTicketComponentProductFormModal-categoryTable">
                            <thead>
                              <tr>
                                <td>Название</td>
                                <td>Цена</td>
                              </tr>
                            </thead>
                            <tbody>
                              {
                                values.specification.categories.map((category, index) => (
                                  <tr key={category.id}>
                                    <td className="MultiTicketComponentProductFormModal-categoryName">
                                      <Select
                                        className="MultiTicketComponentProductFormModal-categorySelect"
                                        name={`specification.categories[${index}].id`}
                                        value={category.id}
                                        onChange={(value) => {
                                          const category = categoriesList.find((c) => c.id === value);
                                          setFieldValue(`specification.categories[${index}].id`, value);
                                          setFieldValue(`specification.categories[${index}].name`, category.name);
                                        }}
                                        onBlur={() => setFieldTouched(`specification.categories[${index}].id`, true)}
                                      >
                                        {
                                          categoriesList
                                            .map((category) => (
                                              <Select.Option
                                                key={category.id}
                                                value={category.id}
                                                disabled={
                                                  values.specification.categories
                                                    .map((selectedCategory, index) => selectedCategory.id)
                                                    .includes(category.id)
                                                }
                                              >
                                                {category.name}
                                              </Select.Option>
                                            ))
                                        }
                                      </Select>
                                      <ErrorMessage name={`specification.categories[${index}].name`}>
                                        {(msg) => <div
                                          className="MultiTicketComponentProductFormModal-error">{msg}</div>}
                                        MultiTicketComponentProductFormModal </ErrorMessage>
                                    </td>
                                    <td>
                                      <InputNumber
                                        value={category.price}
                                        onChange={(value) => setFieldValue(`specification.categories[${index}].price`, value)}
                                        onBlur={() => setFieldTouched(`specification.categories[${index}].price`, true)}
                                      />
                                      <ErrorMessage name={`specification.categories[${index}].price`}>
                                        {(msg) => <div
                                          className="MultiTicketComponentProductFormModal-error">{msg}</div>}
                                      </ErrorMessage>
                                    </td>
                                    <td className="MultiTicketComponentProductFormModal-deleteCategoryButton">
                                      <Button
                                        icon={<DeleteOutlined />}
                                        onClick={() => {
                                          values.specification.categories.splice(index, 1);
                                          setFieldValue(`specification.categories`, values.specification.categories);
                                        }}
                                      />
                                    </td>
                                  </tr>
                                ))
                              }
                            </tbody>
                          </table>
                          <ErrorMessage name={`specification.categories`}>
                            {(msg) => (
                              typeof msg === 'string' &&
                              <div className="MultiTicketComponentProductFormModal-error">{msg}</div>
                            )}
                          </ErrorMessage>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <Divider />
                        </td>
                      </tr>
                      {
                        values.specification.quantity !== UNLIMITED_QUANTITY
                        && <tr>
                          <td>
                            Количество:<br />
                            <InputNumber
                              value={values.specification.quantity}
                              onChange={(value) => setFieldValue('specification.quantity', value)}
                              onBlur={() => setFieldTouched('specification.quantity', true)}
                            />
                            <ErrorMessage name={`specification.quantity`}>
                              {(msg) => <div className="MultiTicketComponentProductFormModal-error">{msg}</div>}
                            </ErrorMessage>
                          </td>
                        </tr>
                      }
                      <tr>
                        <td>
                          <Checkbox
                            checked={values.specification.quantity === UNLIMITED_QUANTITY}
                            onChange={(e) => {
                              const defaultValue = initialValues.specification.quantity === UNLIMITED_QUANTITY ? defaultProduct.specification.quantity : initialValues.specification.quantity;
                              setFieldValue('specification.quantity', e.target.checked ? UNLIMITED_QUANTITY : defaultValue)
                            }}
                            onBlur={() => setFieldTouched('specification.quantity', true)}
                          >
                            Без ограничений
                          </Checkbox>
                          <ErrorMessage name={`specification.quantity`}>
                            {(msg) => <div className="MultiTicketComponentProductFormModal-error">{msg}</div>}
                          </ErrorMessage>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <Divider />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Группа:<br />
                          <Select
                            style={{ width: '200px' }}
                            value={values.productGroupId}
                            onChange={(value) => setFieldValue('productGroupId', value)}
                            onBlur={() => setFieldTouched('productGroupId', true)}
                          >
                            <Select.Option value={null}>Не выбрана</Select.Option>
                            {productGroups.map(productGroup => (
                              <Select.Option
                                key={productGroup.id}
                                value={productGroup.id}
                              >
                                {productGroup.name}
                              </Select.Option>
                            ))}
                          </Select>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  {errors?.summary &&
                    <div className="MultiTicketComponentProductFormModal-error">{errors.summary}</div>}
                </Form>
              </div>
            </Modal>
          )
        }}
      </Formik>
    );
  }
}

export default MultiTicketComponentProductFormModal;
