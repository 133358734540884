import React from 'react';
import { Button, Collapse, notification, Space } from 'antd';
import { Form, Formik, FormikProps } from 'formik';
import { ValidationError } from 'yup';

import './ApproveTask.scss';

import {
  getInitialValues,
  getValidationSchema,
  ipBeneficiaryInitialValues,
  ulBeneficiaryInitialValues,
  ValidationSchema,
  getVerificationSchema,
} from '../form-sections/form';

import { showErrors } from '../../../../utils/form-helpers/antd-formik';
import { OrganizationInfo } from '../form-sections/OrganizationInfo';
import { CeoInfo } from '../form-sections/CeoInfo';
import { AddressesInfo } from '../form-sections/AddressesInfo';
import { PhonesInfo } from '../form-sections/PhonesInfo';
import { BankInfo } from '../form-sections/BankInfo';
import { UserInfo } from '../form-sections/UserInfo';
import { BeneficiaryType } from '../../../../constants/tinkoff';
import { BeneficiaryInfo } from '../form-sections/BeneficiaryInfo';
import { ParentAccountInfo } from '../form-sections/ParentAccountInfo';

interface ApproveTaskProps {
  workflowId: string;
  onLoad: () => Promise<{
    status: string,
    data: ValidationSchema,
    error?: any,
    errorMessage?: string
  }>;
  onUpdate: (data: ValidationSchema) => Promise<any>;
  onApprove: () => Promise<any>;
  onDeny: () => Promise<any>;
  onCancel: () => Promise<any>;
}

interface ApproveTaskState {
  status: string;
  initialValues: ValidationSchema;
  error?: any;
  errorMessage?: string;
  beneficiaryType: BeneficiaryType;
}

class ApproveTask extends React.Component<ApproveTaskProps, ApproveTaskState> {
  constructor(props: any) {
    super(props);
    this.state = {
      status: 'pending-verification',
      initialValues: getInitialValues(BeneficiaryType.UlResident),
      beneficiaryType: BeneficiaryType.UlResident,
    };
    this.renderError = this.renderError.bind(this);
    this.onApprove = this.onApprove.bind(this);
    this.onDeny = this.onDeny.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }

  async componentDidMount() {
    const { onLoad } = this.props;
    const result = await onLoad();
    this.setState((state) => ({
      ...state,
      status: result.status,
      initialValues: result.data,
      // eslint-disable-next-line max-len
      beneficiaryType: ('ipBeneficiary' in result.data) ? result.data.ipBeneficiary?.type : result.data.ulBeneficiary.type,
      error: result.error,
      errorMessage: result.errorMessage,
    }));
  }

  async onUpdate(props: FormikProps<ValidationSchema>) {
    let data;
    const { beneficiaryType } = this.state;
    try {
      data = getValidationSchema(beneficiaryType).validateSync(props.values, { abortEarly: false });
    } catch (error: any) {
      if (error instanceof ValidationError) {
        notification.error({ message: 'Форма содержит неверные данные.' });
        showErrors(error, props);
      }
      return;
    }
    const { onUpdate, onLoad } = this.props;
    if (await onUpdate(data)) {
      const result = await onLoad();
      this.setState((state) => ({
        ...state,
        status: result.status,
        initialValues: result.data,
        error: result.error,
        errorMessage: result.errorMessage,
      }));
    }
  }

  async onApprove() {
    const { onApprove, onLoad } = this.props;
    if (await onApprove()) {
      notification.info({ message: 'Данные пользователя утверждены.' });
      const result = await onLoad();
      this.setState((state) => ({
        ...state,
        status: result.status,
        initialValues: result.data,
        error: result.error,
        errorMessage: result.errorMessage,
      }));
    }
  }

  async onDeny() {
    const { onDeny, onLoad } = this.props;
    if (await onDeny()) {
      notification.info({ message: 'В регистрации отказано.' });
      const result = await onLoad();
      this.setState((state) => ({
        ...state,
        status: result.status,
        initialValues: result.data,
        error: result.error,
        errorMessage: result.errorMessage,
      }));
    }
  }

  async onCancel() {
    const { onCancel, onLoad } = this.props;
    if (await onCancel()) {
      notification.info({ message: 'Регистрация отменена' });
      const result = await onLoad();
      this.setState((state) => ({
        ...state,
        status: result.status,
        initialValues: result.data,
      }));
    }
  }

  verified(values: ValidationSchema) {
    try {
      const { beneficiaryType } = this.state;
      getVerificationSchema(beneficiaryType).validateSync(values);
      return true;
    } catch (error: any) {
      return false;
    }
  }

  renderError() {
    const { error, errorMessage } = this.state;
    return error && (
      <Collapse collapsible="header">
        <Collapse.Panel header={`Описание последней ошибки: ${errorMessage}`} key="1">
          <pre className="ApproveTask-error">{JSON.stringify(error, null, 2)}</pre>
        </Collapse.Panel>
      </Collapse>
    );
  }

  render() {
    const { workflowId } = this.props;
    const { status, initialValues: stateInitialValues, beneficiaryType } = this.state;

    return (
      <div className="ApproveTask">
        {`Задача: ${workflowId}`}
        <br />
        <br />
        {this.renderError()}
        <br />
        <Formik
          initialValues={stateInitialValues}
          validationSchema={getValidationSchema(beneficiaryType)}
          enableReinitialize
          onSubmit={() => {
          }}
        >
          {(props) => (
            <Form>
              <ParentAccountInfo />
              <UserInfo />
              <OrganizationInfo />
              <CeoInfo />
              <AddressesInfo />
              <PhonesInfo />
              <BeneficiaryInfo
                beneficiaryType={beneficiaryType}
                onBeneficiaryTypeChange={(type) => {
                  this.setState({ beneficiaryType: type });
                  if (type === BeneficiaryType.UlResident) {
                    props.setValues({
                      ...props.values,
                      ulBeneficiary: ulBeneficiaryInitialValues,
                    });
                  } else {
                    props.setValues({
                      ...props.values,
                      ipBeneficiary: ipBeneficiaryInitialValues,
                    });
                  }
                }}
              />
              <BankInfo />

              <br />
              <br />
              <br />
              <Space>
                {
                  status === 'pending-approval' && props.dirty && (
                    <Button disabled={props.isSubmitting} onClick={() => this.onUpdate(props)}>
                      Сохранить изменения
                    </Button>
                  )
                }
                {
                  status === 'pending-approval' && this.verified(props.values) && !props.dirty && (
                    <Button disabled={props.isSubmitting} type="primary" onClick={this.onApprove}>
                      Подписать регистрацию
                    </Button>
                  )
                }
              </Space>
              {
                status === 'pending-approval' && (
                  <>
                    <br />
                    <br />
                    <hr />
                    <br />
                    <Space>
                      <Button disabled={props.isSubmitting} onClick={this.onDeny}>
                        Отказать в регистрации
                      </Button>
                      <Button disabled={props.isSubmitting} onClick={this.onCancel}>
                        Отказать в регистрации (без письма)
                      </Button>
                    </Space>
                  </>
                )
              }
            </Form>
          )}
        </Formik>
      </div>
    );
  }
}

export default ApproveTask;
