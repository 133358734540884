import React, { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ModalProps, notification } from 'antd';
import { produce } from 'immer';
// eslint-disable-next-line import/no-extraneous-dependencies
import dayjs from 'dayjs';
import { FormikHelpers } from 'formik';
import * as Yup from 'yup';

import { BaseCalendarProductFormModal, ProgramType, ScheduleType } from './BaseCalendarProductFormModal';
import { createProduct, updateProduct } from '../../../../actions/productsActions';
import { productGroupsSelector } from '../../../../selectors/productsSelectors';
import { submitValidate } from '../../../../utils/form-helpers/antd-formik';
import { IntegrationMode, SchemaType } from '../../../../constants/schema';
import { getDefaultCategory } from './categories/Categories';
import { getEventDefaultValue } from './schedule/Schedule';
import { getLocation, Location } from '../../../../api/location';

const validationSchema = Yup.object().shape({
  productGroupId: Yup.string().nullable().default(null),
  specification: Yup.object({
    type: Yup.string().default(SchemaType.BaseCalendar),
    version: Yup.number().default(1),
    title: Yup.string().required('Введите название продукта.'),
    description: Yup.string().required('Введите описание продукта.'),
    locationId: Yup.string().required('Введите город.'),
    categories: Yup.array().min(1, 'Добавьте хотя бы одну категорию').of(
      Yup.object().shape({
        id: Yup.string().required(),
        name: Yup.string().required('Введите название категории.'),
        price: Yup.number().required('Введите цену продукта.').min(100, 'Мин. 100'),
        maxParticipants: Yup.number()
          .typeError('Введите число')
          .when('programType', {
            is: ProgramType.Individual,
            then: (schema) => schema.required(),
            otherwise: (schema) => schema.optional(),
          }),
      }),
    ),
    address: Yup.string().required('Введите адрес'),
    productCategory: Yup.array().of(Yup.string()).min(1, 'Выберите хотя бы одну категорию'),
    schedule: Yup.array().min(1, 'Добавьте хотя бы одну дату').of(
      Yup.object().shape({
        date: Yup.string().required(),
        events: Yup.array().min(1, 'Добавьте хотя бы одно время').of(
          Yup.object().shape({
            startTime: Yup.string().required(),
            endTime: Yup.string().optional(),
            quantity: Yup.number().typeError('Введите число').required(),
          }),
        ),
      }),
    ),
  }).required(),
});

interface Props extends ModalProps {
  open?: boolean;
  onCancel: () => void;
  onComplete: () => void;
  product: any;
}

const defaultProduct = {
  specification: {
    type: SchemaType.BaseCalendar,
    version: 1,
    // title: 'Новый товар 1',
    // description: 'Описание нового товара',
    categories: [getDefaultCategory(ProgramType.Group)],
    duration: {
      minutes: 15,
      hours: 0,
      days: 0,
    },
    closeSalesIn: {
      minutes: 0,
      hours: 0,
      days: 0,
    },
    scheduleType: ScheduleType.TimeExact,
    schedule: [{
      date: dayjs(),
      events: [
        getEventDefaultValue(ScheduleType.TimeExact),
      ],
    }],
    ageLimit: 0,
    productCategory: [],
    programType: ProgramType.Group,
    productGroupId: null,
    photos: [],
  },
  integrationMode: IntegrationMode.ShowEverywhere,
};

export const BaseCalendarProductFormModalContainer: React.FC<Props> = ({ open, onCancel, onComplete, product }) => {
  const productGroups = useSelector(productGroupsSelector);
  const dispatch = useDispatch();
  const [location, setLocation] = useState<Location>();
  const locationId = product?.specification?.locationId;
  useEffect(() => {
    if (locationId) {
      getLocation(locationId).then((fetchedLocation) => setLocation(fetchedLocation));
    }
  }, [locationId]);
  return (
    <BaseCalendarProductFormModal
      open={open}
      onCancel={onCancel}
      productGroups={productGroups}
      product={product}
      initLocation={location}
      initialValues={product || defaultProduct}
      onSubmit={useCallback(async (values: any, formikBag: FormikHelpers<any>) => {
        if (!submitValidate(values, validationSchema, formikBag)) {
          notification.error({ message: 'Проверьте правильность заполнения формы' });
          return;
        }
        try {
          const savedProduct = produce(values, (draft: any) => {
            // eslint-disable-next-line no-param-reassign
            draft.specification.title = draft.specification.title.trim();
            // eslint-disable-next-line no-param-reassign
            draft.specification.description = draft.specification.description.trim();
            // eslint-disable-next-line no-param-reassign
            draft.specification.important = draft.specification?.important?.trim();
            // eslint-disable-next-line no-param-reassign
            draft.specification.included = draft.specification?.included?.trim();
            // eslint-disable-next-line no-param-reassign
            draft.specification.notIncluded = draft.specification?.notIncluded?.trim();
            // eslint-disable-next-line no-param-reassign
            draft.specification.address = draft.specification.address.trim();
            draft.specification.categories.forEach((category: any) => {
              // eslint-disable-next-line no-param-reassign
              category.name = category.name.trim();
            });
            draft.specification.schedule.forEach((scheduleItem: any) => {
              // eslint-disable-next-line no-param-reassign
              scheduleItem.date = dayjs(scheduleItem.date).format('YYYY-MM-DD');
              scheduleItem.events.sort((a: any, b: any) => {
                if (!a.endTime) {
                  return a.startTime.localeCompare(b.startTime);
                }
                if (a.startTime !== b.startTime) {
                  return a.startTime.localeCompare(b.startTime);
                }
                return a.endTime.localeCompare(b.endTime);
              });
            });
            draft.specification.schedule.sort((a: any, b: any) => (
              dayjs(a.date).valueOf() - dayjs(b.date).valueOf()
            ));
          });
          if (product) {
            await new Promise((resolve, reject) => {
              dispatch(updateProduct(product.id, savedProduct, resolve, reject));
            });
          } else {
            await new Promise((resolve, reject) => {
              dispatch(createProduct(savedProduct, resolve, reject));
            });
          }
          onComplete();
        } catch (error) {
          formikBag.setErrors(error as any);
        } finally {
          formikBag.setSubmitting(false);
        }
      }, [onComplete, product, dispatch])}
    />
  );
};

export default BaseCalendarProductFormModalContainer;
